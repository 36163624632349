import React, { useState, useEffect } from 'react';
import { useController } from './controller';
import * as S from './styles';
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconBrands from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet';
import Particles from "@tsparticles/react";
import moment from 'moment-timezone';
import axios from 'axios';

const ERP = (props: any) => {
    const controller = useController();

    return (
        <>
            <Particles
                id="tsparticles"
                particlesLoaded={controller.particlesLoaded as any}
                options={controller.options}
            />
            <S.shortcutIcons id="'shortcutIcons'>">
                <S.shortcutIconDiv id='shortcutIconDivPlans' onClick={() => {
                    document.getElementById('plans')?.scrollIntoView({ behavior: 'smooth' });
                }}>
                    <S.shortcutIcon icon={icons.faDollarSign} />
                </S.shortcutIconDiv>
                <S.shortcutIconDiv onClick={() => {
                    window.open('https://wa.me/5535998030543?text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20Arezdot%20ERP.', '_blank');
                }}>
                    {/* @ts-ignore */}
                    <S.shortcutIcon icon={iconBrands.faWhatsapp} />
                </S.shortcutIconDiv>
            </S.shortcutIcons>
            <S.mainContainer
                onScrollCapture={(e) => controller.decideButtonVisibility()}>
                <Helmet>
                    <title>Arezdot ERP | Gestão simples e eficaz</title>
                </Helmet>
                <S.body>
                    <S.header id="pageTop">
                        <S.headerLogoWithText>
                            <S.imageLogo>
                                <S.imgLogoYellowPart alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_glow_only.png" />
                                <S.imgLogoDraw alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_draw_only.png" />
                            </S.imageLogo>
                            <S.headerTitle>{'Arezdot'.toUpperCase()}</S.headerTitle>
                        </S.headerLogoWithText>
                        <S.buttonsContainer>
                            <S.button onClick={() => {
                                window.location.href = 'https://erp.arezdot.com/login';
                            }}>
                                Entrar
                            </S.button>
                            <S.button onClick={() => {
                                window.location.href = 'https://erp.arezdot.com/signup';
                            }}>
                                Criar conta
                            </S.button>
                        </S.buttonsContainer>
                    </S.header>
                    <S.main>
                        <S.mainTextArea>
                            <S.mainTitle>Arezdot ERP</S.mainTitle>
                            <S.mainSubtitle>Fácil, rápido, seguro e inteligente</S.mainSubtitle>
                            <S.mainText>Nosso ERP é para empresários que desejam mais controle, eficiência e crescimento. Chega de desorganização e horas perdidas! Centralize tudo, tome decisões inteligentes e veja sua empresa e seus lucros crescerem.</S.mainText>
                        </S.mainTextArea>
                        <S.formArea>
                            {!controller.formSent && (<S.freeTrialText>7 dias de teste grátis, é só cadastrar!{/* <S.freeTrialCounter>(Válido até {moment().tz('America/Sao_Paulo').add(14, 'days').format('DD/MM/YYYY')})</S.freeTrialCounter> */}</S.freeTrialText>)}
                            
                            {!controller.formSent ? (
                                <S.form id="submitFormArezdot" onSubmit={(e: any) => { 
                                    e.preventDefault(); 
                                    controller.submitForm(e)
                                 }}>
                                    <input type="hidden" name="answer_start" value={new Date().toISOString()} />
                                    <S.label>Nome Completo</S.label>
                                    <S.input autoCapitalize='words' autoComplete="off" type="text" name="name" required />
                                    <S.label>WhatsApp (Com DDD)</S.label>
                                    <S.input id="whatsappInput" inputMode='numeric' onKeyUp={() => { controller.phoneMask('a3s4csgs6M') }} autoComplete="off" type="text" name="a3s4csgs6M" required />
                                    {/* <S.label>Email (opcional)</S.label>
                                    <S.input inputMode='email' autoCapitalize='off' autoComplete="off" type="text" name="F2opSXzBsK" />
                                    <S.label>Instagram (opcional)</S.label>
                                    <S.input autoCapitalize='off' autoComplete="off" type="text" name="NC2ZuAf1kc" /> */}
                                    <S.submitButton type="submit">Quero Acelerar Resultados!</S.submitButton>
                                    <S.secureFormText>
                                        <S.freeTrialIcon icon={icons.faLock} />
                                        <S.freeTrialSubtext>Seus dados estão seguros e não serão compartilhados.</S.freeTrialSubtext>
                                    </S.secureFormText>
                                </S.form>
                            ) : (
                                <S.instaFollowAsk onClick={() => {
                                    window.open('https://www.instagram.com/arezdot_/', '_blank');
                                }}>
                                    <S.instaFollowAskTitle>Obrigado! Em breve entraremos em contato! 🎯</S.instaFollowAskTitle>
                                    <S.instaFollowAskText>
                                        Estamos muito felizes por você dar este passo em direção à gestão inteligente e ao crescimento do seu negócio.<br /><br />
                                        Mas isso é só o começo! No nosso Instagram, você encontrará <strong>conteúdos exclusivos</strong> para empresários como você, que buscam <strong>progresso, consistência e inteligência nos negócios.</strong><br /><br />
                                        ✅ <strong>Dicas práticas</strong> para gestão e organização.<br />
                                        ✅ <strong>Ensinamentos estratégicos</strong> para impulsionar resultados.<br />
                                        ✅ <strong>Inspiração e motivação</strong> para superar desafios e alcançar suas metas.<br /><br />
                                        <strong>Não perca essa oportunidade de crescer ainda mais!</strong><br />
                                        Clique aqui para acessar nosso perfil e faça parte da nossa comunidade. 🚀🚀
                                    </S.instaFollowAskText>
                                    <S.instaFollowAskButton>
                                        {/* @ts-ignore */}
                                        <S.instaFollowAskButtonIcon icon={iconBrands.faInstagram} />
                                        <S.instaFollowAskButtonText>Seguir Arezdot no Instagram</S.instaFollowAskButtonText>
                                    </S.instaFollowAskButton>
                                </S.instaFollowAsk>
                            )}
                        </S.formArea>
                    </S.main>
                    <S.itemKeyBenefitsTitle>O que Faz do Arezdot ERP a Melhor Escolha?</S.itemKeyBenefitsTitle>
                    <S.keyBenefitsContainer>
                        {controller.keyBenefits.map((item, index) => {
                            return (
                                <S.keyBenefit index={index} key={'keyBenefit_' + index}>
                                    <S.keyBenefitTitle>{item.title}</S.keyBenefitTitle>
                                    <S.keyBenefitDescription>{item.description}</S.keyBenefitDescription>
                                </S.keyBenefit>
                            )
                        })}
                    </S.keyBenefitsContainer>
                    <S.functionsContainer id="functionsContainer">
                        <S.functionsHeader>
                            <S.backAndNextButton active={controller.currentFunctionShowing == 0 ? false : true} icon={icons.faChevronLeft} onClick={() => {
                                controller.changeFunctionShowing('back');
                            }} />
                            <S.functionTitle>{controller.functionalities[controller.currentFunctionShowing]?.title}</S.functionTitle>
                            <S.backAndNextButton active={controller.currentFunctionShowing == (controller.functionalities?.length - 1) ? false : true} icon={icons.faChevronRight} onClick={() => {
                                controller.changeFunctionShowing('next');
                            }} />
                        </S.functionsHeader>
                        <S.functionsBits items={controller.functionalities}>
                            {controller.functionalities.map((item, index) => {
                                return (
                                    <S.functionBit key={'functionality_' + index} currentFunction={controller.currentFunctionShowing}>
                                        {/* @ts-ignore */}
                                        {/* <S.functionIcon icon={icons[item.icon]} /> */}
                                        <S.functionImage src={`https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/main_page/images/${item.image}`} />
                                        <S.functionInfoArea>
                                            <S.functionImageMobile src={`https://arezdot.sfo3.cdn.digitaloceanspaces.com/erp/fixed_files/main_page/images/${item.image}`} />
                                            <S.functionDescription>
                                                {item.description}
                                            </S.functionDescription>
                                        </S.functionInfoArea>
                                    </S.functionBit>
                                )
                            })}
                        </S.functionsBits>
                    </S.functionsContainer>
                    <S.plansArea id="plans">
                        <S.itemTitle>Planos</S.itemTitle>
                        <S.plansPeriods>
                            <S.planPeriod selected={controller.planPeriod == 0} onClick={() => { controller.setPlanPeriod(0) }}>Mensal</S.planPeriod>
                            <S.planPeriod selected={controller.planPeriod == 1} onClick={() => { controller.setPlanPeriod(1) }}>Semestral (-5%)</S.planPeriod>
                            <S.planPeriod selected={controller.planPeriod == 2} onClick={() => { controller.setPlanPeriod(2) }}>Anual (-10%)</S.planPeriod>
                        </S.plansPeriods>
                        <S.plansContainer planShowing={controller.planShowing}>
                            <S.plansNextBackButton icon={icons.faChevronLeft} onClick={() => {
                                controller.changePlan('back');
                            }} />
                            <S.plansBits>
                                {controller.plans.map((item, index) => {
                                    return (
                                        <S.planBit planSelected={controller.planShowing} showPlan={index === controller.planShowing} key={'plan_' + index} id={'plan_' + index} className={'plan_' + index} onClick={() => {
                                            window.location.href = 'https://erp.arezdot.com/signup';
                                        }}>
                                            <S.planTitle>{item.name}< br /></S.planTitle>
                                            <S.planItems>
                                                {item.planItems.map((item, index) => {
                                                    return (
                                                        <S.planItem key={'planItem_' + index}>{item}</S.planItem>
                                                    )
                                                })}
                                            </S.planItems>
                                            <S.planValue><span className='planValueExtra'>{controller.planPeriod != 0 ? `R$ ${((item.value / 100)).toFixed(2).toString().replace('.', ',')} / mês` : null}</span> <br />R$ {((item.value / 100) * controller.plansPeriods[controller.planPeriod].planValue).toFixed(2).toString().replace('.', ',')} / mês</S.planValue>
                                            <S.planButton className='hire_btn'>Acelerar Resultados</S.planButton>
                                        </S.planBit>
                                    )
                                })}
                            </S.plansBits>
                            <S.plansNextBackButton icon={icons.faChevronRight} onClick={() => {
                                controller.changePlan('next');
                            }} />
                        </S.plansContainer>
                    </S.plansArea>
                </S.body>
            </S.mainContainer>
        </>
    )
}

export default ERP;